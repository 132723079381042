/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import scrollToElement from "../utils/scrollToElement";
import DOMPurify from "dompurify";

class FilterGalleryForm {
    constructor() {
        if (document.querySelector(".gallery-section")) {
            this.init();
        }
    }

    handleChapters(targetVideoPlayer) {
        function updateChapters(player) {
            var currentTime = player.currentTime();
            (this.el, this.startTime <= currentTime && currentTime < this.endTime) ? this.el.addClass("active") : this.el.removeClass("active");
        }

        function handleChapterChange(player) {
            player.currentTime($(this).data("time"));
        }

        let chapters = $($(targetVideoPlayer.el()).data("chapters-container"));

        //check for chapters container next to video
        if (!chapters.length) return false;

        //empty chapters container
        chapters.html("");

        //chapters demo
        let chapterTT = [].filter.call(targetVideoPlayer.remoteTextTracks(), function(tt) {
            return tt.kind === "chapters";
        });

        //check if metadata type is chapters
        if (!chapterTT) {
            $(".chapters__tab").hide();
            $(".transcript__tab a").tab("show");
            $(targetVideoPlayer.el()).removeClass("hasChapters");
            return false;
        } else {
            $(targetVideoPlayer.el()).addClass("hasChapters");
            $(".chapters__tab").show();
            $("#webinar-chapters").show();
        }

        //loop through chapter metadata
        let cuesAra = chapterTT[0].cues;
        if (cuesAra && cuesAra.length > 0) {
            for (var i = 0; i < cuesAra.length; i++) {
                var $el = $('<li><span class="start-time">' + videojs.formatTime(cuesAra[i].startTime, "M:SS") + "</span> " + cuesAra[i].text + "</li>");

                //create chapter elements
                $el.data("time", cuesAra[i].startTime)
                    .on("click", $.proxy(handleChapterChange, $el, targetVideoPlayer))
                    .appendTo(chapters);

                cuesAra[i].el = $el;

                targetVideoPlayer.remoteTextTracks()[0].addEventListener("cuechange", $.proxy(updateChapters, cuesAra[i], targetVideoPlayer));
            }
        }
    }

    init() {
        const $this = this;
        const $form = $("#filter-form");
        const $gallery = $(".carousel-inner");
        const $carousel = $("#video-gallery");
        const $prev = $(".controls-wrap .prev");
        const $next = $(".controls-wrap .next");
        const $controls = $(".controls-wrap");
        const $resetItems = $(".gallery-item");
        let length = $("#video-gallery .item").length;
        const itemsDesktop = $form.data("desktop-items");
        const itemsMobile = $form.data("mobile-items");

        let BRIGHTCOVEACCOUNT = 5186843675001;
        let BRIGHTCOVEPLAYERID = "OR7JLFY9F";

        //add class to body
        document.body.classList.add("filterGalleryFormPage");

        $form
            .on("input reset", function(e) {
                setTimeout(function() {
                    //creates a javascript array of objects, ready to be encoded as a JSON string
                    let arr = $form.serializeArray();
                    let category = "";
                    let selectors = "";
                    let itemsPerPage = $(window).width() <= 768 ? itemsMobile : itemsDesktop;
                    let totalItems = $resetItems.length;
                    let pages = Math.ceil(totalItems / itemsPerPage);
                    let keywordStr =
                        $("#keyword-search").length && $("#keyword-search").val().length > 2
                            ? $("#keyword-search")
                                  .val()
                                  .toLowerCase()
                            : "";
                    let $items = [];
                    // sets item variables
                    if (arr.length < 1 || selectors == "") {
                        $items = $resetItems;
                    }
                    //appends items to gallery-items ( this shows as empty in the final result?)
                    $items.appendTo(".gallery-items");
                    //clears out gallery
                    $gallery.html("");
                    //clears control (this is invisible on live site)
                    $(".carousel-indicators").html("");

                    // creates selector data tags based on form selection
                    for (var index = 0; index < arr.length; index++) {
                        if (selectors !== "") selectors;
                        selectors += `[data-tags*='${arr[index].name}:${arr[index].value}']`;

                        // if index is the last or the index ahead does not equal the name of the curren index (for doubling??)
                        if (index === arr.length - 1 || arr[index + 1].name !== arr[index].name) {
                            // filters items based on selections made
                            $items = $items.filter(selectors);
                            selectors = "";
                        }

                        category = arr[index].name;
                    }

                    $items = $items.filter(function(index, item) {
                        return item.innerText.toLowerCase().indexOf(keywordStr) > -1;
                    });

                    // update pagination when filtered
                    pages = Math.ceil($items.length / itemsPerPage);

                    //update page item count
                    $(".item-count").text($items.length + " items");

                    //create pages of items
                    for (var i = 0; i < pages; i++) {
                        $items.slice(i * itemsPerPage, (i + 1) * itemsPerPage).wrapAll('<div class="item">');
                        $(".carousel-indicators").append('<li data-target="#video-gallery" data-slide-to="' + i + '"></li>');
                    }

                    //activate first page
                    $(".gallery-items .item")
                        .appendTo(".carousel-inner")
                        .eq(0)
                        .addClass("active");
                    $(".carousel-indicators li:first-child").addClass("active");

                    //configure nav
                    if ($("#video-gallery .item").length > 0) {
                        $next.removeClass("invisible");
                        $prev.addClass("invisible");
                        $(".controls-wrap").removeClass("invisible");
                    } else {
                        $(".controls-wrap").addClass("invisible");
                    }

                    totalItems < itemsPerPage || $items.length <= itemsPerPage ? $(".controls-wrap").addClass("invisible") : ($next.removeClass("invisible"), $(".controls-wrap").removeClass("invisible"));
                }, 0);
            })
            .trigger("reset");
        $controls.on("click", function(e) {
            function setControls(index, length) {
                if (index === 0 && length === 1) {
                    $(".controls-wrap").addClass("invisible");
                }
                if (index === 0) {
                    $prev.addClass("invisible");
                } else {
                    $prev.removeClass("invisible");
                }
                if (index === length - 1) {
                    $next.addClass("invisible");
                } else {
                    $next.removeClass("invisible");
                }
            }
            setTimeout(function() {
                let activeItem = Array.from(document.querySelectorAll("#video-gallery .item.active")),
                    galleryItems = Array.from(document.querySelectorAll("#video-gallery .item")),
                    indicators = $(".carousel-indicators li"),
                    index = galleryItems.indexOf(activeItem[0]);
                setControls(index, galleryItems.length);

                indicators
                    .removeClass("active")
                    .filter(":nth-child(" + (index + 1) + ")")
                    .addClass("active");
            }, 0);
        });
        $carousel.on("slide.bs.carousel", function(e) {
            var activeList = $(e.relatedTarget),
                index = activeList.index(),
                length = $("#video-gallery .item").length;
            index === 0 ? $prev.addClass("invisible") : $prev.removeClass("invisible"); //first question
            index === length - 1 ? $next.addClass("invisible") : $next.removeClass("invisible");

            setTimeout(function() {
                $(".carousel-indicators li")
                    .removeClass("active")
                    .filter(":nth-child(" + (index + 1) + ")")
                    .addClass("active");
            }, 1);

            if ($(window).width() < 768) scrollToElement("#video-gallery");
        });

        $("#webinar-video-modal")
            .on("show.bs.modal", function(e) {
                let vimeoLink = $(e.relatedTarget).data("vimeo-link");
                let modalBody = document.querySelector(".video-modal-body");
                let vimeoWrapper = modalBody.querySelector(".vimeo-player-wrapper");
                let brightcoveWrapper = modalBody.querySelector(".brightcove-player-wrapper");

                //grab video ID
                let videoId = $(e.relatedTarget).data("video-id");
                //grab title
                let title = $(e.relatedTarget)
                    .find(".video-title")
                    .html();
                //grab description
                let description = $(e.relatedTarget)
                    .find(".video-description")
                    .html();
                if (vimeoLink) {
                    let image = modalBody.querySelector(".vimeo-poster-image");
                    let posterSrc = e.relatedTarget.dataset.posterImage;
                    image.style.backgroundImage = `url(${posterSrc})`;
                    vimeoWrapper.style.display = "block";
                    brightcoveWrapper.style.display = "none";
                }
                $(this)
                    .find(".modal-title")
                    .html(title);
                $(this)
                    .find(".modal-description")
                    .html(description);

                let hasTranscript = false;
                let transcriptHtml = $(".video_transcript.hidden[data-video-id='" + videoId + "']").html();

                if (transcriptHtml !== undefined && transcriptHtml.length > 0) {
                    hasTranscript = true;
                    let pane = $("#webinar-video-modal #video-transcript .pane-wrapper");

                    pane.empty();
                    pane.append(transcriptHtml);
                } else {
                    hasTranscript = false;
                    $("#webinar-video-modal #video-transcript .pane-wrapper").empty();
                }

                $(".modal-footer").fadeIn(1200);
            })
            .on("hide.bs.modal", function(e) {
                let vimeoLink = e.currentTarget.querySelector("iframe");
                if (vimeoLink) {
                    return;
                } else {
                    let player = document.querySelector("video");
                    if (player) {
                        let myPlayer = videojs(player);
                        myPlayer.dispose();
                    }
                }
                $(".modal-footer").hide();
            });
        let url = new URL(DOMPurify.sanitize(document.location));
        let hash = url.hash;

        let gallertItem = $('.gallery-item[data-id-anchor="' + hash + '"]');

        if (gallertItem.length && document.querySelector(".gallery-section").hasAttribute("x-ignore")) {
            gallertItem.trigger("click");
        }

        $(".pane-wrapper").on("scroll", function(ev) {
            this.scrollTop + this.offsetHeight + 1 >= this.scrollHeight
                ? $(this)
                      .parent()
                      .addClass("scroll-to-bottom")
                : $(this)
                      .parent()
                      .removeClass("scroll-to-bottom");
        });
        function clearActiveState() {
            $(".chapters__tab, .transcript__tab, .tab-pane").removeClass("active");
        }
        $(".gallery-item").on("click", function() {
            clearActiveState();
        });
    }
}

export default FilterGalleryForm;
