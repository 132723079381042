import createCookie from "../utils/createCookie";
import getCookie from "../utils/getCookie";

const audienceController = cookieName => {
    return {
        cookieValue: getCookie(cookieName),
        init() {
            this.$watch("cookieValue", value => {
                createCookie(cookieName, value);
                this.$dispatch("set-audience");
            });
        }
    };
};

export default audienceController;
