import { getContent } from "../utils/getContent";

const getProp = () => async (path, prop) => {
    const contentNode = await getContent(path).then(response => {
        return response.json();
    });
    return contentNode[prop] ? contentNode[prop] : "";
};

export default getProp;
