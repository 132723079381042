class ViewTranscript {
    constructor() {
        this.viewTranscript = document.querySelectorAll(".transcriptToggle");
        this.init();
    }

    init() {
        const $this = this;
        if ($this.viewTranscript) {
            $this.viewTranscript.forEach(node => {
                node.addEventListener("click", e => {
                    e.preventDefault();
                    node.classList.toggle("active");
                    const transcript = document.querySelector("." + node.getAttribute("data-transcript-target"));
                    if (transcript) {
                        transcript.classList.toggle("active");
                        transcript.toggleAttribute("aria-hidden");
                        transcript.toggleAttribute("inert");
                    }
                });
            });
        }
    }
}

export default ViewTranscript;
