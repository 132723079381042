import convertNodeListToArray from "../utils/convertNodeListToArray";
import insertAfter from "../utils/insertAfter";
import getSelectors from "../utils/getSelectors";
import getOffset from "../utils/getOffset";

function _addAttributeIndicator(ele, attrName, attrValue) {
    if (!_checkAttribute(ele, attrName)) {
        ele.setAttribute(attrName, attrValue);
    }
    return false;
}

function _checkRequireField(el, instance) {
    // if (el.value === null || el.value === '' || el.value === undefined) {
    //   return false;
    // }
    // return true;
    if (el.disabled) return true;
    if (el.type !== "checkbox" && el.type !== "radio") {
        if (el.value === null || el.value === "" || el.value === undefined) {
            return false;
        }
    } else if (el.type === "checkbox" && !el.dataset.groupCheckbox) {
        return el.checked;
    } else if (el.type === "radio") {
        const _group = convertNodeListToArray(instance.elements.form.querySelectorAll(`input[name="${el.name}"]`));

        let _r = false;

        _group.map(x => {
            if (x.checked) {
                _r = true;
            }
            return x;
        });
        return _r;
    } else if (el.type === "checkbox" && el.dataset.groupCheckbox) {
        const _checkboxGroup = instance.elements.form.querySelectorAll(`input[name="${el.name}"]:checked`).length;
        const minCheck = el.dataset.mincheck * 1;
        if (_checkboxGroup < minCheck) {
            return false;
        }
    }

    return true;
}

function _checkPatternField(el) {
    if (el.value !== null && el.value !== "" && el.value !== undefined) {
        return new RegExp(`^(${el.pattern})$`).test(el.value);
    }
    return true;
}

function _renderErrorContainer(el, idValue) {
    const parent = el.parentElement;
    const _errEl = document.createElement("div");
    let _radioWrapper;

    _errEl.setAttribute("id", idValue);
    _errEl.classList.add("error");
    _errEl.classList.add("error-container");

    if ((el.type === "checkbox" && !el.dataset.groupCheckbox) || ((el.type === "select-one" || el.type === "select-multiple") && parent.classList.contains("select"))) {
        insertAfter(parent, _errEl);
    } else if (el.type === "checkbox" && el.dataset.groupCheckbox) {
        const _wrapper = el.closest("div");
        const _allEl = _wrapper.querySelectorAll(`input[name="${el.name}"]`);
        const _lastEl = _allEl[_allEl.length - 1];
        const _error = _wrapper.querySelector(".error");
        if (!_error) {
            insertAfter(_lastEl.parentElement, _errEl);
        }
    } else if (el.type === "radio") {
        _radioWrapper = parent;
        insertAfter(_radioWrapper, _errEl);
    } else {
        insertAfter(el, _errEl);
    }
    return _errEl;
}

function _getErrorContainer(ele) {
    const idErrContainer = _checkAttribute(ele, "data-validate-id");
    if (idErrContainer) {
        const attr = ele.getAttribute("data-validate-id");
        const errContainerEle = document.getElementById(attr);
        if (errContainerEle) {
            if (!errContainerEle.classList.contains("error")) errContainerEle.classList.add("error");
            if (!errContainerEle.classList.contains("error-container")) errContainerEle.classList.add("error-container");
            return errContainerEle;
        }
        return false;
    }
    return false;
}

function _showError(el, type) {
    const _checkboxGroupEl = el.type === "checkbox" && el.dataset.groupCheckbox;
    let _el = el;
    if (_checkboxGroupEl) {
        const _divWrapper = el.closest("div");
        _el = _divWrapper.querySelector("input");
    }
    let _errEl = _getErrorContainer(_el);
    const parent = el.parentElement;
    let _radioWrapper;

    if (el.type === "radio") {
        _radioWrapper = parent;
        if (_radioWrapper.nodeName === "LABEL") {
            _radioWrapper = parent.parentElement;
            if (_radioWrapper.querySelector(".success")) return;
        }
    }

    if (!_errEl) {
        _errEl = _renderErrorContainer(_el, _el.getAttribute("data-validate-id"));
    }

    _errEl.innerText = _el.dataset[`error${type}`];

    if (!el.dataset.groupCheckbox) {
        el.classList.remove("success");
        el.classList.add("invalid");
    }
    if (el.type === "checkbox" && el.dataset.groupCheckbox) {
        const _divWrapper = el.closest("div");
        _divWrapper.classList.remove("success");
        _divWrapper.classList.add("invalid");
    }

    el.closest(".form-group") ? el.closest(".form-group").classList.add("form-error") : "";
    if (el.type === "checkbox" && !el.dataset.groupCheckbox) {
        el.parentElement.classList.add("invalid");
    }
    if (el.type === "radio") {
        _radioWrapper.classList.add("invalid");
    }
    if (parent.classList.contains("select")) {
        parent.classList.add("invalid");
    }
}

function _showSuccess(el) {
    let _errEl;
    el.closest(".form-group") ? el.closest(".form-group").classList.remove("form-error") : "";

    if (!el.dataset.groupCheckbox) {
        el.classList.remove("invalid");
        el.classList.add("success");
    } else {
        const _divWrapper = el.closest("div");

        _divWrapper.classList.add("success");
        _divWrapper.classList.remove("invalid");
    }

    if (el.type === "checkbox" && el.dataset.groupCheckbox) {
        const _divWrapper = el.closest("div");
        _divWrapper.classList.add("success");
        _divWrapper.classList.remove("invalid");
    }

    const parentEle = el.parentElement;
    if (el.type === "checkbox" && el.dataset.groupCheckbox) {
        const _divWrapper = el.closest("div");
        const _elFirst = _divWrapper.querySelector("input");
        _errEl = _getErrorContainer(_elFirst);
    } else {
        _errEl = _getErrorContainer(el);
    }

    if (el.type === "checkbox" && !el.dataset.groupCheckbox) {
        parentEle.classList.remove("invalid");
    }
    let _radioWrapper;

    if (el.type === "radio") {
        _radioWrapper = parentEle;

        if (_radioWrapper.nodeName === "LABEL") {
            _radioWrapper = parentEle.parentElement;
        }
        _radioWrapper.classList.remove("invalid");
    }

    if (parentEle.classList.contains("select")) {
        parentEle.classList.remove("invalid");
    }

    if (!_errEl) return;
    _errEl.innerText = "";
}

function _doSubmit(instance) {
    if (typeof instance.setting.events.beforeDoSuccess === "function") instance.setting.events.beforeDoSuccess(instance);
    if (typeof instance.setting.events.doSuccess === "function") {
        instance.setting.events.doSuccess(instance);
    } else {
        const { form } = instance.elements;
        const isForm = form.tagName === "FORM" ? form : form.closest("form");
        if (isForm && instance.setting.submitForm) {
            isForm.submit();
        }
    }
}

function _displayValidateResult(instance, isSubmitRequest) {
    const _instance = instance;

    let isNestedGroupValidated;
    let groupWrapper = document.querySelectorAll("[data-requiredsubgroup]");

    if (groupWrapper.length < 1) {
        isNestedGroupValidated = true;
    }
    groupWrapper.forEach(wrapper => {
        isNestedGroupValidated = validateNestedGroup(wrapper);
    });

    if (_instance.data.errorRequires.length === 0 && _instance.data.errorPatterns.length === 0 && isNestedGroupValidated) {
        if (isSubmitRequest) {
            _doSubmit(instance);
        }
    } else {
        _instance.data.errorRequires.map(x => _showError(x, "Require"));
        _instance.data.errorPatterns.map(x => _showError(x, "Pattern"));
        if (typeof instance.setting.events.doError === "function") instance.setting.events.doError(instance);
    }
}

function _checkAttribute(field, attribute) {
    if (attribute === "required") {
        return field.getAttribute(attribute) !== null && field.getAttribute(attribute) !== undefined;
    }

    return field.getAttribute(attribute) !== null && field.getAttribute(attribute) !== undefined && field.getAttribute(attribute) !== "";
}

function _validateForm(instance, isSubmitRequest) {
    const _instance = instance;
    _instance.data.errorRequires = [];
    _instance.data.errorPatterns = [];

    _instance.elements.requires.map(x => {
        if (_checkAttribute(x, "required")) {
            if (!_checkRequireField(x, _instance)) {
                _instance.data.errorRequires.push(x);
            }
        }

        if (_checkAttribute(x, "data-confirm-field")) {
            const valueConfirm = _instance.elements.form.querySelector(`input[name="${x.dataset.confirmField}"]`).value;
            if (x.value !== valueConfirm) {
                _instance.data.errorPatterns.push(x);
            }
        }
        return x;
    });

    _instance.elements.patterns.map(x => {
        if (_checkAttribute(x, "pattern")) {
            if (!_checkPatternField(x)) {
                _instance.data.errorPatterns.push(x);
            }
        }
        return x;
    });

    _displayValidateResult(_instance, isSubmitRequest);
}

function _validateField(instance, field) {
    if (field.nodeName === "INPUT" || field.nodeName === "SELECT" || field.nodeName === "TEXTAREA") {
        const _instance = instance;

        if (_checkAttribute(field, "required")) {
            const _errorRequires = _instance.data.errorRequires.filter(x => x === field);
            if (!_checkRequireField(field, _instance)) {
                if (_errorRequires.length === 0) _instance.data.errorRequires.push(field);
            } else if (_errorRequires.length > 0) {
                const _newErrorRequires = _instance.data.errorRequires.filter(x => x !== field);
                _instance.data.errorRequires = _newErrorRequires;
                _showSuccess(field, _instance);
            } else {
                _showSuccess(field, _instance);
            }
        } else {
            const _errorRequires = _instance.data.errorRequires.filter(x => x === field);
            if (_errorRequires.length !== 0) {
                const _newErrorRequires = _instance.data.errorRequires.filter(x => x !== field);
                _instance.data.errorRequires = _newErrorRequires;
            }
            if (field.type === "radio") {
                const _wrapper = field.parentElement.parentElement;

                let fieldValidate = _wrapper.querySelector("[data-validate-id]");
                // Lets check if there is a nested group of radio inputs
                const isNested = _wrapper.querySelectorAll("[data-validate-id]").length > 1;
                if (isNested) {
                    fieldValidate = _wrapper.querySelectorAll("[data-validate-id]")[1];
                }

                _showSuccess(fieldValidate, _instance);
            } else {
                _showSuccess(field, _instance);
            }
        }

        if (_checkAttribute(field, "pattern")) {
            const _errorPatterns = _instance.data.errorPatterns.filter(x => x === field);
            if (!_checkPatternField(field)) {
                if (_errorPatterns.length === 0) {
                    _instance.data.errorPatterns.push(field);
                }
            } else if (_errorPatterns.length) {
                const _newErrorPatterns = _instance.data.errorPatterns.filter(x => x !== field);
                _instance.data.errorPatterns = _newErrorPatterns;
                _showSuccess(field, _instance);
            }
        } else {
            if (field.type === "radio") {
                const _wrapper = field.parentElement.parentElement;
                const fieldValidate = _wrapper.querySelector("[data-validate-id]");
                _showSuccess(fieldValidate, _instance);
            } else {
                if (!field.dataset.groupCheckbox) {
                    _showSuccess(field, _instance);
                }
            }
        }

        if (_checkAttribute(field, "data-confirm-field")) {
            const _errorPatterns = _instance.data.errorPatterns.filter(x => x === field);
            const _valueMatchField = _instance.elements.form.querySelector(`input[name="${field.dataset.confirmField}"]`).value;
            if (field.value !== _valueMatchField) {
                if (_errorPatterns.length === 0) {
                    _instance.data.errorPatterns.push(field);
                }
            } else {
                const _newErrorPatterns = _instance.data.errorPatterns.filter(x => x !== field);
                _instance.data.errorPatterns = _newErrorPatterns;
                _showSuccess(field, _instance);
            }
        }

        if (field.type !== "radio") {
            _displayValidateResult(_instance, false);
        }
    }
}

function _checkNumber(e) {
    const field = e.target;
    if (_checkAttribute(field, "data-number")) {
        if (e.keyCode < 48 || e.keyCode > 57) {
            e.preventDefault();
        }
    }
}

function validateNestedGroup(wrapper) {
    let isValid;
    if (!wrapper.classList.contains("hidden")) {
        let oneIsChecked = wrapper.querySelectorAll("input[type=radio]:checked").length > 0;
        if (!oneIsChecked) {
            if (!wrapper.classList.contains("invalid")) {
                wrapper.classList.add("invalid");
                let div = document.createElement("div");
                div.classList.add("error", "error-container");
                if (wrapper.dataset.espanol) {
                    div.append("Por favor, selecciona una respuesta.");
                } else {
                    div.append("Please select an answer.");
                }

                wrapper.append(div);
            }
            isValid = false;
        } else {
            let errContainer = wrapper.querySelector(".error-container");
            if (errContainer) {
                errContainer.remove();
                wrapper.classList.remove("invalid");
            }
            isValid = true;
        }
    } else {
        isValid = true;
    }
    return isValid;
}

function _bindEvents(instance) {
    // Listener for Sub Group inputs
    let groupWrapper = document.querySelectorAll("[data-requiredsubgroup]");
    groupWrapper.forEach(wrapper => {
        let inputs = wrapper.querySelectorAll("input[type=radio]");
        inputs.forEach(input => {
            input.addEventListener("change", () => {
                validateNestedGroup(wrapper);
            });
        });
    });

    instance.elements.form.addEventListener("submit", e => {
        if (e.target === instance.elements.form) {
            e.preventDefault();
            e.stopPropagation();
            _validateForm(instance, true);
        }
    });

    instance.elements.form.addEventListener("click", e => {
        if (e.target.type === "button") {
            e.preventDefault();
            e.stopPropagation();
            if (instance.setting.dynamicField) {
                const { form } = instance.elements;
                const requiredEleList = convertNodeListToArray(form.querySelectorAll("[required]"));
                const patternEleList = convertNodeListToArray(form.querySelectorAll("[pattern]"));

                instance.elements.requires = requiredEleList;
                instance.elements.patterns = patternEleList;
            }
            _validateForm(instance, true);
        }
        if (e.target.type === "submit") {
            e.preventDefault();
            e.stopPropagation();
            _validateForm(instance, true);
            // check scroll invalid
            if (instance.setting.invalidScroll) {
                const invalid = instance.elements.form.querySelector(".invalid:not([disabled='disabled'])");
                if (invalid) {
                    const targetScroll = invalid.nodeName === "DIV" ? invalid : invalid.parentElement;
                    window.scrollTo({
                        top: getOffset(targetScroll),
                        behavior: "smooth"
                    });
                }
            }
        }
    });

    instance.elements.form.addEventListener("input", e => {
        _validateField(instance, e.target);
    });

    instance.elements.form.addEventListener("focusout", e => {
        if (e.target.type === "radio") return;
        _validateField(instance, e.target);
    });

    instance.elements.form.addEventListener("change", e => {
        _validateField(instance, e.target);
    });

    instance.elements.form.addEventListener("keypress", e => {
        _checkNumber(e);
    });
}

class FormValidation {
    /**
     * Class constructor
     * @param {Object} setting setting for new instance plugin.
     * @param {String} setting.selector The css selector query to get DOM elements will apply this plugin.
     * @param {Object} setting.events Define callbacks for events.
     * @param {Function} setting.events.initialized Callback will fire when 1 instance installed
     * @param {Function} setting.events.initializedAll Callback will fire when ALL instances installed
     */
    constructor(setting) {
        const defaultSetting = {
            selector: "form[data-form-validation]",
            submitForm: true,
            dynamicField: false,
            invalidScroll: false,
            events: {
                initialized() {},
                initializedAll() {},
                beforeDoSuccess() {}
                // doSuccess() {},
                // doError() {},
            }
        };

        const s = Object.assign({}, defaultSetting, setting || {});
        this.setting = s;
        this.instances = [];
        this.init(s);
        return this.instances;
    }

    init(setting) {
        const $this = this;
        const els = getSelectors(setting.selector);

        els.map((x, index) => {
            const obj = {};
            const s = Object.assign({}, $this.setting, x.dataset || {});

            obj.setting = s;

            const requiredEleList = convertNodeListToArray(x.querySelectorAll("[required]"));
            const patternEleList = convertNodeListToArray(x.querySelectorAll("[pattern]"));

            if (requiredEleList.length) {
                requiredEleList.map((item, ind) => {
                    _addAttributeIndicator(item, "data-validate-id", `validate-id-${index}-${ind}`);
                });
            }

            if (patternEleList.length) {
                patternEleList.map((item, ind) => {
                    _addAttributeIndicator(item, "data-validate-id", `validate-id-${index}-${ind}`);
                });
            }

            obj.elements = {
                form: x,
                requires: requiredEleList,
                patterns: patternEleList
            };

            obj.data = {
                errorRequires: [],
                errorPatterns: []
            };

            // Avoid HTML5 buit-in validation
            x.setAttribute("novalidate", "");

            _bindEvents(obj);
            $this.instances.push(obj);

            if (typeof obj.setting.events.initialized === "function") obj.setting.events.initialized(obj);
            return obj;
        });

        if (typeof $this.setting.events.initializedAll === "function") $this.setting.events.initializedAll(els);
    }
}

export default FormValidation;
