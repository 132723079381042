const audienceReactiveContent = (path, prop) => {
    return {
        "x-html": prop,
        "x-init"() {
            //server side rendered html will display if given
            this.$data[prop] = this.$el.innerHTML.trim();

            //if no server side html, grab it via Delivery API
            if (!this.$data[prop].length) {
                this.$nextTick(() => {
                    this.$data[prop] = this.$getProp(path, prop);
                });
            }
        },
        //listen for set-audience event and re-fetch content
        "@set-audience.window"() {
            this.$data[prop] = this.$getProp(path, prop);
        }
    };
};

export default audienceReactiveContent;
