import BaseForm from "./baseForm";
import getParameterByName from "./queryParams";
import getCookie from "../utils/getCookie";
import createHiddenField from "../utils/createHiddenField";
class signupForm extends BaseForm {
    constructor() {
        super(document.querySelector(".crm-signup"));
        const signUpFormEl = document.querySelector(".crm-signup");
        if (signUpFormEl) {
            this.form = "[data-signup-form]";
            this.signupForm = document.getElementById("signUp");
            this.signupBtn = document.querySelector(".sign-up-box a.btn");
            this.closeDialogBtn = document.querySelector("#btnOK");
            this.init();
        }
    }

    onOpenSignUp() {
        const $this = this;
        if ($this.signupBtn) {
            $this.signupBtn.addEventListener("click", e => {
                e.preventDefault();
                this.registerCopy = document.querySelector(".form-description strong.register.hidden");
                $this.registerCopy.classList.remove("hidden");
                $this.signupBtn.classList.add("hidden");
                document.querySelector("#signUp").classList.remove("hidden");
                document.querySelector("body").classList.add("crm-pod-open");
                $this.signupForm.classList.remove("hidden");
                document.querySelector("#signUp input").focus();
            });
        }
    }

    setDisplayItems(elements, display) {
        if (elements && elements.length > 0) {
            for (let i = 0; i < elements.length; i++) {
                elements[i].style.display = display ? display : "block";
            }
        }
    }

    onCloseDialog() {
        const $this = this;
        let errorMessageModalDialog = document.getElementById("errorMessageModalDialog");
        if ($this.closeDialogBtn && errorMessageModalDialog) {
            $this.closeDialogBtn.addEventListener("click", () => {
                this.registerCopy = document.querySelector(".form-description strong.register");
                if ($this.registerCopy) {
                    $this.registerCopy.classList.add("hidden");
                }
                errorMessageModalDialog.style.display = "none";
                errorMessageModalDialog.classList.remove("in");
            });
        }
    }

    init() {
        const $this = this;
        const isPatientEls = document.querySelectorAll('[name="isPatient"]');
        const subGroup = document.querySelector(".dupi-form__subGroup");
        const formElement = document.getElementById("signUp");

        if ($this.signupBtn) {
            $this.signupForm.classList.add("hidden");
        }

        $this.onOpenSignUp();
        $this.onValidateForm();
        $this.onCloseDialog();
        $this.onValidateByApi();

        getParameterByName("copayIneligible") == "true" ? createHiddenField("copayIneligible", "true") : "";

        getCookie("QueryString__MOC") ? createHiddenField("mediaOriginCode", getCookie("QueryString__MOC"), formElement) : "";

        isPatientEls.forEach(function(el) {
            el.addEventListener("click", e => {
                if (e.target.defaultValue == "CAREGIVER") {
                    subGroup.classList.remove("hidden");
                } else {
                    subGroup.classList.add("hidden");
                }
            });
        });

        $(window).on("load", function() {
            $('.sign-up-box [data-toggle="tooltip"]').tooltip({
                trigger: "hover",
                container: "body"
            });
        });
    }
}

export default signupForm;
